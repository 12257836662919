import React from 'react';
import Option from './Option';

const FunList = ({ onSelect }) => {
    const options = [
        { name: 'Bowling', image: '/images/bowling.png' },
        // Add more options here
    ];

    return (
        <div className="list-container">
            <div className="list">
                {options.map((option, index) => (
                    <Option key={index} name={option.name} image={option.image} onSelect={onSelect} />
                ))}
            </div>
        </div>
    );
};

export default FunList;
