import React from 'react';
import Option from './Option';

const DrinksList = ({ onSelect }) => {
    const options = [
        { name: 'Starbucks', image: '/images/starbucks.png' },
        { name: 'Cafe Ah-Roma', image: '/images/cafeahroma.png' },
        // Add more options here
    ];

    return (
        <div className="list-container">
            <div className="list">
                {options.map((option, index) => (
                    <Option key={index} name={option.name} image={option.image} onSelect={onSelect} />
                ))}
            </div>
        </div>
    );
};

export default DrinksList;
