import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Navbar from './Navbar.js';
import FoodList from './FoodList.js';
import DrinksList from './DrinksList.js';
import FunList from './FunList.js';

function App() {
  const sendEmail = (selectedOption) => {
    emailjs.send('service_5wpq22y', 'template_v7jwypd', { from_name: "Ramandeep", message: selectedOption }, '6NZH6iulNjK-z6ENA')
      .then((response) => {
          console.log(response.text);
          alert(`${selectedOption}`);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="App">
      <Navbar/>
      <div className='Body'>
        <Router>
          <Routes>
            <Route path="/*" element={<FoodList onSelect={sendEmail} />}/>
            <Route path="/food" element={<FoodList onSelect={sendEmail} />}/>
            <Route path="/drinks" element={<DrinksList onSelect={sendEmail} />}/>
            <Route path="/fun" element={<FunList onSelect={sendEmail} />}/>
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
