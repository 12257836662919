import React from 'react';
import Option from './Option';

const FoodList = ({ onSelect }) => {
    const options = [
        { name: 'Bombay Chaat', image: '/images/bombaychaat.png' },
        { name: 'Chick-fil-A', image: '/images/chickfila.png' },
        { name: 'Rascal House', image: '/images/rascalhouse.png' },
        { name: 'Subway', image: '/images/subway.gif' },
        { name: 'Viking Public House', image: '/images/vikingpublichouse.png' },
        // Add more options here
    ];

    return (
        <div className="list-container">
            <div className="list">
                {options.map((option, index) => (
                    <Option key={index} name={option.name} image={option.image} onSelect={onSelect} />
                ))}
            </div>
        </div>
    );
};

export default FoodList;
