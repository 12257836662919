import React from 'react'

export default function Navbar() {
    return (
        <nav className="navbar">
            <a href="/food">Food</a>
            <a href="/drinks">Drinks</a>
            <a href="/fun">Fun</a>
        </nav>
    )
}
