import React from 'react';

const Option = ({ name, image, onSelect }) => {
    return (
        <div className="option" onClick={() => onSelect(name)}>
            <img src={image} alt={name} className="option-image" />
            <p className="option-name">{name}</p>
        </div>
    );
};

export default Option;
